.signup {
  background-color: #b2c5cb;
  width: 50%;
  margin: auto;
  border-radius: 10px;
  height: auto;
  padding: 10px;
  box-shadow: 5px 5px 5px 5px #808080;
  margin-top: 2%;
}

form {
  width: 100%;
}

.signUpButton {
  background-color: #ef9090;
  border-style: none;
  color: white;
}

label {
  color: #2c4f74;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: flex-start;
}

.signup-input {
  height: 30px;
  margin: 10px 5px 10px 5px;
  border: 1px solid black;
  display: flex;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}

.error-signup-input {
  height: 30px;
  margin: 10px 5px 10px 5px;
  border: 1px solid red;
  background-color: rgb(255, 0, 0, 0.2);
  display: flex;
  border-radius: 10px;
  width: 80%;
  padding: 10px 10px 10px 10px;
}

.dropdown {
  height: 30px;
  margin: 10px 5px 10px 5px;
  margin-left: auto;
  border: 1px solid black;
  display: flex;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}

.row-container {
  display: flex;
  flex-direction: column;
}

.overflow-row-container {
  display: flex;
  flex-direction: column;
}

.checkbox-container {
  margin: auto;
  width: 80%;
}

.input-container {
  padding: 2px;
  display: flex;
  flex-direction: column;
}

.line-container {
  padding: 2px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  overflow: auto;
}

.signup-registration {
  padding-bottom: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.success-login {
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

a:link {
  text-decoration: none;
  color: #8054c9;
}

a:visited {
  text-decoration: none;
  color: #8054c9;
}

a:hover {
  text-decoration: none;
  color: #8054c9;
}

a:active {
  text-decoration: none;
  color: #8054c9;
}

.nav-select {
  color: #2c4f74;
}

.signup-page {
  /* Customize the scrollbar track */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Customize the scrollbar thumb (the draggable part) */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Customize the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
