nav {
    height: 80px;
    background-color: #3C3293;    
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right-nav {
    color: #2E6AA1;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    margin-right: 2%;
    justify-content: flex-end;
}

#welcome {
    color: #2E6AA1;
    font-family: Roboto;
    margin-top: 2%;
}

.nav-link {
    margin: 2%;
    color: #B2C5CB;
}

.nav-link:hover {
    color: #8054C9;
    cursor: pointer;
}

.nav-text {
    margin: 2%;
    color: #B2C5CB;
}

a {
    color: #B2C5CB;
}