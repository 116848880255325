@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.title-container {
    text-align: center;
    color: #3c3293;
    font-size: 2em;
    margin-top: 5%;
  }
  
  .section {
    margin-bottom: 2%;
  }
  
  .title-text-box {
    color: #3c3293;
    margin-top: 2%;
  }
  
  .text-box {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .info-text {
    padding: 10px;
  }