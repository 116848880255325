@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.petRoom {
  width: 40%;
  background-color: #d3c5c5;
  border-radius: 90px;
  padding: 5px;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: #2e6aa1;
}

.right-icons {
  margin-left: 10%;
  width: 7%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-icons {
  margin-right: 10%;
  width: 7%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.click-icons {
  margin-bottom: 20%;
}

.click-icons:hover {
  cursor: pointer;
}

.home-nav-select {
  color: white;
}

.home-nav-select:hover {
  color: lightblue;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s;
}
