@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700");

.leftPageWrapper {
  display: grid;
  align-items: center;
  right: 49%;
  position: absolute;
  
}


.new_left_page {
  grid-row: 2;
  grid-column: 1;
  z-index: 100;
  margin-top: 2%;
}

.leftpage1 {
  grid-row: 2;
  grid-column: 1;
  z-index: 100;
  margin-left: -16%;
  width: 110%;
  margin-top: 2%;
}

.leftpage2 {
  grid-row: 2;
  grid-column: 1;
  z-index: 200;
  margin-left: -14%;
  width: 110%;
  margin-top: 2%;
}

.leftpage3 {
  grid-row: 2;
  grid-column: 1;
  z-index: 300;
  margin-left: -12%;
  width: 110%;
  margin-top: 2%;
}

.rightPageWrapper {
  display: grid;
  align-items: center;
  left: 56%;
  position: absolute;
}

.new_right_page {
  grid-row: 2;
  grid-column: 2;
  z-index: 100;
  margin-left: -12%;
  margin-top: 2%;
}

.rightpage1 {
  grid-row: 2;
  grid-column: 2;
  margin-left: -16%;
  width: 110%;
  z-index: 300;
  margin-top: 2%;
}

.rightpage2 {
  grid-row: 2;
  grid-column: 2;
  margin-left: -14%;
  width: 110%;
  z-index: 200;
  margin-top: 2%;
}

.rightpage3 {
  grid-row: 2;
  grid-column: 2;
  margin-left: -12%;
  width: 110%;
  z-index: 100;
  margin-top: 2%;
}

.bookmark {
  max-width: 100%;
  height: 90%;
  z-index: 1200;
  margin-left: -18%;
  position: absolute;
}

.goal-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  top: 2%;
  margin-left: 10%;
  margin-bottom: 100%;
  grid-row: 2;
  grid-column: 1;
  text-align: center;
  z-index: 999;
  width: 80%;
}

.middle-line {
  position: absolute;
  height: 810px;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  width: 30%;
  margin-left: 50%;
  height: 40%;
  margin-top: 4%;
}

.inside-modal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-input {
  width: 80%;
  background-color: #ececec;
  font-size: 20px;
}

.progress-title {
  display: flex;
  align-items: left;
  margin-top: 4%;
}

.radio-group {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
}

.tip-icon {
  color: #9f9f9f;
}

.nav-options {
  display: flex;
  margin-top: 4%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.progress-error {
  font-size: 16px;
  color: red;
}

.numerical-selection {
  background-color: #ececec;
  margin: 10px 5px 10px 5px;
  border-style: none;
  display: flex;
  align-self: left;
  border-radius: 10px;
  width: 100%;
  padding: 10px 10px 10px 10px;
  box-shadow: 2px 2px 2px 2px #808080;
}

.goal-selection {
  display: flex;
  width: 88%;
  flex-direction: row;
  justify-content: space-between;
}

.modal-half-input {
  display: flex;
  width: 40%;
  background-color: #ececec;
  font-size: 14px;
}

.example-text {
  margin-top: 2%;
  margin-bottom: 2%;
}

.goal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 1px 1px #808080;
  border-radius: 5px;
  width: 75%;
}

.current-goal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1%;
  margin-left: 4%;
}

.number-text {
  color: #8054c9;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

#upIcon {
  cursor: pointer;
}

#downIcon {
  cursor: pointer;
}

#upIcon:hover {
  transform: scale(1.5);
  color: green;
}

#downIcon:hover {
  transform: scale(1.5);
  color: red;
}

.recommendation-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 1px 1px #808080;
  border-radius: 5px;
  width: 90%;
  margin-top: 2%;
}

.goal-text {
  margin-left: 4%;
}

.selection-container {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
}

.modified-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 6%;
}

.viewGoalButton {
  display: flex;
  justify-content: flex-end;
}

.recommendedGoalButton {
  display: flex;
  justify-content: space-between;
}

/* eating goals */

.eating-goal-header {
  font-size: 16px;
}

.text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button-container {
  width: 80%;
  margin: auto;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.single-button-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.reflection-tab {
  position: absolute;
  margin-right: 70%;
  z-index: 250;
}

.reflection-tab:hover {
  cursor: pointer;
}

.achievements-tab {
  position: absolute;
  margin-top: 15%;
  z-index: 125;
}

.achievements-tab:hover {
  cursor: pointer;
}

.goals-tab {
  position: absolute;
  margin-left: 70%;
  margin-bottom: 25%;
  z-index: 250;
}

.goals-tab:hover {
  cursor: pointer;
}

.gallery-tab {
  position: absolute;
  z-index: 150;
}

.gallery-tab:hover {
  cursor: pointer;
}

.goal-description {
  cursor: pointer;
}

.behaviorInput {
  display: flex;
}

.feedback-page {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.feedback-num {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reflect-wrapper:hover {
  cursor: pointer;
}

.page-modal {
  background-color: #b2c5cb;
  padding: 5px;
  border-radius: 20px;
  width: 30%;
  margin-left: 50%;
  height: 80%;
  margin-top: 9%;
}


.modal-text {
  margin: auto;
}
