* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  height: 100vh;
  background-size: 100% 100%;
}

h2 {
  width: 100%;
}